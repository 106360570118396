<template>
  <view-base ref="base">
    <div class="mb-3">
      <h4><b>Pedidos entregues nesta semana - {{ currentMonday }} - {{ currentSunday }}</b> ({{ ordersCurrentWeek.length }} - {{ totalCurrentWeek.toFixed(2) }}€)</h4>
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersCurrentWeek"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          empty-message="Nenhuma entrega nessa semana"
        />
      </div>
    </div>
    <div class="mb-3">
      <h4><b>Pedidos entregues na última semana - {{ lastMonday }} - {{ lastSunday }}</b> ({{ ordersLastWeek.length }} - {{ totalLastWeek.toFixed(2) }}€)</h4>
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersLastWeek"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          empty-message="Nenhuma entrega na última semana"
        />
      </div>
    </div>
    <div>
      <h4><b>Pedidos entregues nos últimos 15 dias</b> ({{ ordersLast15.length }} - {{ totalLast15.toFixed(2) }}€)</h4>
      <div class="row">
        <advanced-table
          :columns="columns"
          :values="ordersLast15"
          :paginated="false"
          :show-filter="false"
          :filter-case-sensitive="false"
          :on-select-item="onOrderSelected"
          empty-message="Nenhuma entrega nos últimos 15 dias"
        />
      </div>
    </div>
  </view-base>
</template>

<style scoped>
.card {
  width: 300px;
  display: inline-block;
  vertical-align: middle;
  background: #F0F0F0;
  border-radius: 3px 3px 4px 4px;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid white;

  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import Ripple from 'vue-ripple-directive'

import axios from 'axios'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import ViewBase from '@/views/ViewBase.vue'
import { getUserData } from '@/auth/utils'

const AdvancedTable = () => import('@core/components/AdvancedTableComponent.vue')

export default {
  components: {
    ViewBase,
    AdvancedTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ordersToday: [],
      totalToday: 0,
      ordersCurrentWeek: [],
      totalCurrentWeek: 0,
      ordersLastWeek: [],
      totalLastWeek: 0,
      ordersLast15: [],
      totalLast15: 0,
      userRole: getUserData().role.code,
      storeId: getUserData().store_id,
      columns: [{
          title: 'Loja',
          name: 'storeName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Código',
          name: 'code',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Cliente',
          name: 'eaterName',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
          filtered: false,
      },
      {
          title: 'Hora',
          name: 'formatDate',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      {
          title: 'Valor',
          name: 'deliveryCost',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      {
          title: 'Cidade',
          name: 'delivered_city',
          visible: true,
          sortable: true,
          cellstyle: 'table-cell-bold-sigla',
      },
      ],
      sortColunms: [],
      filteredSize: 0,
    }
  },
  computed: {
    currentMonday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate())
      return moment(today).format('DD/MM/YYYY')
    },
    currentSunday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() + 6)
      return moment(today).format('DD/MM/YYYY')
    },
    lastMonday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() - 7)
      return moment(today).format('DD/MM/YYYY')
    },
    lastSunday: () => {
      const today = new Date()
      today.setDate(today.getDate() - ((today.getDay() + 6) % 7))
      today.setDate(today.getDate() - 1)
      return moment(today).format('DD/MM/YYYY')
    },
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      const self = this
      self.merchantLoadOrdersDeliveredCurrentWeek()
      self.merchantLoadOrdersDeliveredLastWeek()
      self.merchantLoadOrdersDeliveredLast15()
    },
    merchantLoadOrdersDeliveredToday() {
      const self = this
      this.$refs.base.showLoading()
      self.totalToday = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/today`, useJwt.getHeader())
      .then(response => {
        self.ordersToday = response.data.orders ?? []
        self.ordersToday.forEach((order, index) => {
          self.totalToday += parseFloat(self.ordersToday[index].deliveryCost)
          self.ordersToday[index].storeName = self.ordersToday[index].store.name
          self.ordersToday[index].formatDate = moment(self.ordersToday[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersToday[index].link = `https://www.google.com/maps/search/${self.ordersToday[index].delivered_lat},${self.ordersToday[index].delivered_lon}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    merchantLoadOrdersDeliveredCurrentWeek() {
      const self = this
      this.$refs.base.showLoading()
      self.totalCurrentWeek = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/currentWeek`, useJwt.getHeader())
      .then(response => {
        self.ordersCurrentWeek = response.data.orders ?? []
        self.ordersCurrentWeek.forEach((order, index) => {
          self.totalCurrentWeek += parseFloat(self.ordersCurrentWeek[index].deliveryCost)
          self.ordersCurrentWeek[index].storeName = self.ordersCurrentWeek[index].store.name
          self.ordersCurrentWeek[index].formatDate = moment(self.ordersCurrentWeek[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersCurrentWeek[index].link = `https://www.google.com/maps/search/${self.ordersCurrentWeek[index].delivered_lat},${self.ordersCurrentWeek[index].delivered_lon}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    merchantLoadOrdersDeliveredLastWeek() {
      const self = this
      this.$refs.base.showLoading()
      self.totalLastWeek = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/lastWeek`, useJwt.getHeader())
      .then(response => {
        self.ordersLastWeek = response.data.orders ?? []
        self.ordersLastWeek.forEach((order, index) => {
          self.totalLastWeek += parseFloat(self.ordersLastWeek[index].deliveryCost)
          self.ordersLastWeek[index].storeName = self.ordersLastWeek[index].store.name
          self.ordersLastWeek[index].formatDate = moment(self.ordersLastWeek[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersLastWeek[index].link = `https://www.google.com/maps/search/${self.ordersLastWeek[index].delivered_lat},${self.ordersLastWeek[index].delivered_lon}`
          })
      })
      .catch(error => {
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    merchantLoadOrdersDeliveredLast15() {
      const self = this
      this.$refs.base.showLoading()
      self.totalLast15 = 0
      axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/store/${this.storeId}/orders/delivered/last15`, useJwt.getHeader())
      .then(response => {
        self.ordersLast15 = response.data.orders ?? []
        self.ordersLast15.forEach((order, index) => {
          self.totalLast15 += parseFloat(self.ordersLast15[index].deliveryCost)
          self.ordersLast15[index].storeName = self.ordersLast15[index].store.name
          self.ordersLast15[index].formatDate = moment(self.ordersLast15[index].delivered_at).format('DD/MM/YYYY HH:mm')
          self.ordersLast15[index].link = `https://www.google.com/maps/search/${self.ordersLast15[index].delivered_lat},${self.ordersLast15[index].delivered_lon}`
          })
      })
      .catch(error => {
        console.log('error', error)
        this.$refs.base.checkError(error)
        this.$refs.base.showToastError(this.$t(error.response?.data?.error))
      })
      .finally(() => {
        this.$refs.base.hideLoading()
      })
    },
    onOrderSelected(event, data) {
      window.open(data.link, '_blank')
    },
  },
}
</script>
